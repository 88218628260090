import { CliData } from '../api/cli';

type WpCliCommandProps = {
  readonly command: string;
};

export const ParseWPCliCommand = ({ command }: WpCliCommandProps): CliData | null => {
  const splitCommand = command.match(/(".*?"|[^"\s]+)+(?=\s*|\s*$)/g);
  if (!splitCommand) {
    return null;
  }
  const allParams = splitCommand.reduce<string>((prev, curr) => {
    if (curr.startsWith('--')) {
      return prev.length ? `${prev} ${curr}` : curr;
    }
    return prev;
  }, '');
  const allValues = splitCommand.slice(2).reduce<string>((prev, curr) => {
    if (!curr.startsWith('--')) {
      return prev.length ? `${prev} ${curr}` : curr;
    }
    return prev;
  }, '');

  return {
    command: splitCommand[0],
    action: splitCommand[1],
    value: allValues ?? undefined,
    params: allParams ?? undefined,
  };
};
