import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRestoreAutomatedBackupFiles, useRestoreAutomatedBackupDatabase } from 'api/backups';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';

export type RestoreAutomatedBackupForm = {
  plugins: boolean;
  themes: boolean;
  allFiles: boolean;
  database: boolean;
};

type Props = {
  readonly onClose: () => void;
  readonly onRestore: () => void;
  readonly siteId: string;
  readonly restoreId: string | null;
};

export default function RestoreAutomatedBackup({ onClose, onRestore, siteId, restoreId }: Props) {
  const methods = useForm<RestoreAutomatedBackupForm>({
    defaultValues: {
      plugins: false,
      themes: false,
      allFiles: false,
      database: false,
    },
  });
  const { mutateAsync: restoreBackupFiles } = useRestoreAutomatedBackupFiles(siteId);
  const { mutateAsync: restoreBackupDatabase } = useRestoreAutomatedBackupDatabase(siteId);
  const onSubmit = () => {};

  const { handleSubmit, control, setValue, watch, reset } = methods;
  const plugins = watch('plugins');
  const themes = watch('themes');
  const allFiles = watch('allFiles');
  const database = watch('database');
  const atLeastOneChecked = themes || plugins || allFiles || database;
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      action="confirm"
      confirmDisabled={!atLeastOneChecked}
      onClose={onClose}
      open={Boolean(restoreId)}
      onConfirm={async () => {
        let taskId: string | null;

        if (!database) {
          if (allFiles) {
            const result = await restoreBackupFiles({
              restore_type: 'partial',
              files: ['wp-content'],
              backup_id: restoreId!,
            });
            taskId = result.data.result?.task_id ?? null;
          } else {
            const files = [];
            if (themes) {
              files.push('wp-content/themes');
            }
            if (plugins) {
              files.push('wp-content/plugins');
            }
            const result = await restoreBackupFiles({
              files,
              restore_type: 'partial',
              backup_id: restoreId!,
            });
            taskId = result.data.result?.task_id ?? null;
          }
        } else {
          const result = await restoreBackupDatabase({
            backupId: String(restoreId),
          });
          taskId = result.data.result?.task_id ?? null;
        }

        if (taskId) {
          onRestore();
        }

        reset();
        onClose();
      }}
      title={t('backup_tooltip', {
        type: t('backup_label_restore'),
      })}
      description={
        <>
          <Box mb={1}>
            {t('backup_confirmation_description', {
              type: t('backup_label_restore').toLowerCase(),
            })}
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="plugins"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    onChange={() => {
                      setValue('plugins', !plugins);
                    }}
                    checked={plugins}
                    disabled={database || allFiles}
                    control={<Checkbox color="primary" className="checkbox-radio" />}
                    label={<Typography>{t('plugin_plural')}</Typography>}
                    className="checkbox-form-control-label"
                  />
                )}
              />
              <Controller
                control={control}
                name="themes"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    onChange={() => {
                      setValue('themes', !themes);
                    }}
                    checked={themes}
                    disabled={database || allFiles}
                    control={<Checkbox color="primary" className="checkbox-radio" />}
                    label={<Typography>{t('theme_plural')}</Typography>}
                    className="checkbox-form-control-label"
                  />
                )}
              />
              <Controller
                control={control}
                name="allFiles"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    onChange={() => {
                      setValue('allFiles', !allFiles);
                    }}
                    checked={allFiles}
                    disabled={database || plugins || themes}
                    control={<Checkbox color="primary" className="checkbox-radio" />}
                    label={<Typography>{t('all_files')}</Typography>}
                    className="checkbox-form-control-label"
                  />
                )}
              />
              <Controller
                control={control}
                name="database"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    onChange={() => {
                      setValue('database', !database);
                    }}
                    checked={database}
                    disabled={allFiles || plugins || themes}
                    control={<Checkbox color="primary" className="checkbox-radio" />}
                    label={<Typography>{t('database')}</Typography>}
                    className="checkbox-form-control-label"
                  />
                )}
              />
            </form>
          </FormProvider>
        </>
      }
    />
  );
}
