import { useReducer, useEffect, FC } from 'react';
import { styled } from '@mui/material/styles';
import { initialState, reducer, AddThemeState, AddThemeActions } from './addNewTheme.actions';
import { Grid, Tab, InputAdornment, Typography } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useForm, FormProvider } from 'react-hook-form';
import { useSearchThemesOld, useGetInstalledThemesOld } from 'api/themes';
import { Search } from '@mui/icons-material';
import ThemeCard from './ThemeCard';
import ContentViewCard from 'component/base/ContentViewCard';
import { TextField } from 'component/base/TextField';
import { loadingContainer } from 'theme/custom/loading-container';
import PaginationControls from 'component/base/PaginationControls';
import ThemeSkeleton from './ThemeSkeleton';
import { useTranslation } from 'react-i18next';

const PREFIX = 'AddNewTheme';

const classes = {
  loadingContainer: `${PREFIX}LoadingContainer`,
  search: `${PREFIX}Search`,
};

const StyledContentViewCard = styled(ContentViewCard)({
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
  },
  [`& .${classes.search}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
});

interface AddNewThemeProps {
  readonly siteId: string;
}

export const AddNewTheme: FC<AddNewThemeProps> = ({ siteId }) => {
  const [state, dispatch]: [unknown, React.Dispatch<any>] = useReducer<any>(reducer, initialState);
  const { tab, page, perPage, browse, installingTheme } = state as AddThemeState;
  const methods = useForm<{ browse: string }>({
    defaultValues: {
      browse: '',
    },
  });
  const { t } = useTranslation();
  const { handleSubmit, reset } = methods;

  const {
    isLoading,
    data,
    refetch: getThemes,
    isFetching,
  } = useSearchThemesOld({
    id: siteId,
    page,
    perPage,
    query: tab,
    ...(browse !== '' && { browse }),
  });

  useEffect(() => {
    getThemes();
  }, [tab, perPage, page, browse]);

  const themesMetaData = data?.data?.metadata || null;
  const themesList = data?.data?.result || [];
  const { data: installedThemes } = useGetInstalledThemesOld(siteId);

  const handleTabChange = (value: string, browse?: string) => {
    if (!browse) {
      reset();
    }
    dispatch([
      AddThemeActions.CHANGE_TABS,
      {
        tab: value,
        browse: browse ?? '',
        installingTheme: '',
        page: 1,
      },
    ]);

    if (browse) {
      getThemes();
    }
  };

  const onSubmit = ({ browse }: { browse: string }) => {
    dispatch([
      AddThemeActions.CHANGE_TABS,
      {
        tab: 'all',
        browse,
        installingTheme: '',
        page: 1,
      },
    ]);
  };

  const setInstallName = (theme: string) => {
    dispatch([AddThemeActions.SET_INSTALLING_THEME, theme as string]);
  };

  const isThemeInstalled = (name: string) => {
    const themesArr = installedThemes?.data.result || [];
    return themesArr.find(theme => theme.name === name) !== undefined ?? false;
  };

  return (
    <StyledContentViewCard title={<Typography variant="h2">{t('new_themes')}</Typography>}>
      <>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.search}>
            <TextField
              defaultValue=""
              name="browse"
              disabled={isLoading || isFetching}
              placeholder={t('search_all_themes')}
              fullWidth
              endAdornment={
                <InputAdornment position="end" disableTypography component="button">
                  <Search onClick={() => handleSubmit(onSubmit)()} />
                </InputAdornment>
              }
            />
          </form>
        </FormProvider>
        <TabContext value={tab}>
          <TabList
            variant="scrollable"
            onChange={(event: React.ChangeEvent<any>, value: string) => handleTabChange(value)}
          >
            <Tab label={t('all')} value="all" />
            <Tab label={t('blog')} value="blog" />
            <Tab label={t('business')} value="business" />
            <Tab label={t('fashion')} value="fashion" />
            <Tab label={t('food')} value="food" />
            <Tab label={t('real_estate')} value="real+estate" />
            <Tab label={t('sports')} value="sports" />
            <Tab label={t('technology')} value="technology" />
          </TabList>
        </TabContext>
        <Grid container spacing={4} className={classes.loadingContainer}>
          {isLoading || isFetching ? (
            <ThemeSkeleton />
          ) : (
            themesList.map(theme => (
              <ThemeCard
                theme={theme}
                isInstalled={isThemeInstalled(theme.name!)}
                installingTheme={installingTheme}
                setInstallName={setInstallName}
                key={`${theme.name}-${theme.version}`}
              />
            ))
          )}
          {installedThemes && !(isLoading || isFetching) && themesList.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center">
                <strong>{t('no_results_found')}</strong>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        {themesMetaData ? (
          <PaginationControls
            totalRowCount={themesMetaData.total || 0}
            disabled={isLoading || isFetching}
            perPage={perPage}
            onPageChange={(value: number) => dispatch([AddThemeActions.SET_PAGE_NUMBER, value])}
            onPerPageChange={(value: number) => dispatch([AddThemeActions.SET_PER_PAGE, value])}
            perPageOptions={[12, 48, 96]}
          />
        ) : null}
      </>
    </StyledContentViewCard>
  );
};
