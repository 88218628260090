import { useContext, useState } from 'react';
import PaginationControls from 'component/base/PaginationControls';
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { AutomatedBackup, useGetAutomatedBackups } from 'api/backups';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useTranslation } from 'react-i18next';
import ContentViewCard from 'component/base/ContentViewCard';
import { RestoreRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { formatDate } from 'utils/dateFormat';
import RestoreAutomatedBackup from './RestoreAutomatedBackup';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { TaskNotificationContext } from 'component/root/TaskNotificationProvider';

type Props = {
  readonly siteId: string;
};

export const AutomatedBackups = ({ siteId }: Props) => {
  const tableState = usePaginatedListState({ urlStatePrefix: 'automated' });
  const { data, isLoading } = useGetAutomatedBackups(siteId, tableState);
  const loadingState = usePaginatedListLoadingState(
    !!data?.data.result?.length,
    isLoading,
    tableState.params
  );

  const { startWatchingTasks } = useContext(TaskNotificationContext);

  const [restoreId, setRestoreId] = useState<string | null>(null);

  const { t } = useTranslation();

  const backupStatus = (backup: AutomatedBackup) => {
    switch (backup.status) {
      case 'RESTORING':
        return t('restoring');
      default:
        return t('available');
    }
  };

  return (
    <>
      <ContentViewCard title={<Typography variant="h2">{t('automated_backups')}</Typography>}>
        <>
          <TableContainer>
            <Table aria-label={'backups table'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('status')}</TableCell>
                  <TableCell>{t('created')}</TableCell>
                  <TableCell>{t('actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  Array.from({ length: 3 }).map((v, i) => (
                    <TableRow key={`skeleton-${i}`}>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : loadingState === 'noData' ? (
                  <NoResultsFound colSpan={4} />
                ) : (
                  (data?.data.result || []).map(backup => (
                    <TableRow key={backup.created_at?.toString()}>
                      <TableCell>{t('platform_backup')}</TableCell>
                      <TableCell>{backupStatus(backup)}</TableCell>
                      <TableCell>
                        {formatDate({
                          date: new Date(backup.created_at!).toISOString(),
                        })}
                      </TableCell>
                      <TableCell width="10%">
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                t('backup_tooltip', { type: t('backup_label_restore') }) as string
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  setRestoreId(String(backup.recoveryPoint) || null);
                                }}
                                disabled={restoreId !== null && backup.status !== 'DONE'}
                                size="large"
                              >
                                <RestoreRounded />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!isLoading && (
            <PaginationControls
              totalRowCount={data?.data.metadata?.total || 0}
              perPage={tableState.params.perPage}
              onPageChange={page => tableState.setPage(page)}
              onPerPageChange={perPage => tableState.setPerPage(perPage)}
            />
          )}
        </>
      </ContentViewCard>
      <RestoreAutomatedBackup
        onRestore={startWatchingTasks}
        onClose={() => setRestoreId(null)}
        siteId={siteId}
        restoreId={restoreId}
      />
    </>
  );
};
