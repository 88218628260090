import { FC } from 'react';
import { TableRow, TableCell, Grid, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { VerticalAlignBottomRounded, RestoreRounded, DeleteRounded } from '@mui/icons-material';
import { Backup, useGetDownloadLink, useRestoreBackup } from 'api/backups';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';

interface InstalledTableRowProps {
  readonly backup: Backup;
  readonly siteId: string;
  readonly openConfirmationModal: (
    backupid: number,
    type?: 'delete' | 'restore',
    onSubmit?: () => Promise<unknown>
  ) => void;
}

const InstalledTableRow: FC<InstalledTableRowProps> = ({
  backup,
  siteId,
  openConfirmationModal,
}: InstalledTableRowProps) => {
  const { mutateAsync: getDownloadLink, isPending: getDownloadLinkLoading } =
    useGetDownloadLink(siteId);
  const { t } = useTranslation();

  const { mutateAsync: restoreBackup } = useRestoreBackup(siteId);

  const handleRestoreBackup = async () => {
    await restoreBackup({
      backup_database: true,
      backup_directory: true,
      backup_id: String(backup.id),
    });
  };

  const backupStatus = (status: Backup['status']) => {
    switch (status) {
      case 'PROGRESS':
        return t('processing');
      case 'NEW':
        return t('creating');
      case 'ERROR':
        return t('error');
      case 'RESTORING':
        return t('restoring');
      default:
        return t('available');
    }
  };

  const { status, created, label, id, task_id } = backup;

  return (
    <TableRow key={created}>
      <TableCell width="40%">{label}</TableCell>
      <TableCell>{backupStatus(status)}</TableCell>
      <TableCell>{formatDate({ date: created! })}</TableCell>
      <TableCell width="15%">
        <Grid container justifyContent="space-between" direction="row" wrap="nowrap">
          <Grid item>
            <Tooltip arrow placement="top" title={t('backup_tooltip', { type: t('download') })}>
              <IconButton
                onClick={async () => {
                  const resp = await getDownloadLink(task_id!);
                  const downloadLink = resp.data.result?.[0].download_link;

                  if (downloadLink) {
                    const link = document.createElement('a');
                    link.setAttribute('download', `${id}-backup-link`);
                    link.href = downloadLink;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }
                }}
                disabled={status !== 'DONE'}
                size="large"
              >
                {getDownloadLinkLoading ? (
                  <CircularProgress
                    sx={{
                      width: 'auto !important',
                      height: 'auto !important',
                    }}
                  />
                ) : (
                  <VerticalAlignBottomRounded />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              placement="top"
              title={t('backup_tooltip', { type: t('backup_label_restore') })}
            >
              <IconButton
                onClick={() => openConfirmationModal(id!, 'restore', handleRestoreBackup)}
                disabled={status !== 'DONE'}
                size="large"
              >
                <RestoreRounded />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              placement="top"
              title={t('backup_tooltip', { type: t('backup_label_delete') })}
            >
              <IconButton
                onClick={() => openConfirmationModal(id!)}
                disabled={status !== 'DONE'}
                size="large"
              >
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default InstalledTableRow;
